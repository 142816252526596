import { IGroup } from '@bridebook/models/source/models/Countries/Groups.types';
import { ValidationError } from '@bridebook/toolbox/src';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { env } from 'lib/env';
import { AlternateLink, AppAction, AppState, IDeps, Query } from 'lib/types';

export const appError = ({
  error,
  feature = '',
}: {
  error: unknown;
  feature?: string;
}): AppAction => {
  if (!(error instanceof ValidationError)) {
    SentryMinimal().captureException(error, {
      tags: {
        source: 'AppError',
        feature,
      },
    });

    // eslint-disable-next-line no-console
    if (!env.LIVE && console.error) {
      console.log(`AppError feature: ${feature}`); // eslint-disable-line no-console
      console.error('AppError: ', error); // eslint-disable-line no-console
    }
  }

  return {
    type: 'APP_ERROR',
  };
};

export const routeChangeComplete =
  (url: string, query: Query, serverPage: string) =>
  ({ getState }: IDeps): AppAction => {
    const {
      app: { previousPath },
    } = getState();
    return {
      type: 'ROUTE_CHANGE_COMPLETE',
      payload: { url, query, previousPath, serverPage },
    };
  };

export const setPathname = (pathname: string) => ({
  type: 'SET_PATHNAME',
  payload: pathname,
});

export const routeChangeStart = (
  url: Object,
  query: Query,
  nextUrl: string,
  locale: string | undefined,
) => ({
  type: 'ROUTE_CHANGE_START',
  payload: { url, query, nextUrl, locale },
});

export const resizeWindow = (payload: {
  width: number;
  height: number;
  isMobile: boolean;
  isTablet: boolean;
  isLandscape: boolean;
  isDesktopDesign: boolean;
  isDesktop: boolean;
}): AppAction => ({
  type: 'RESIZE_WINDOW',
  payload,
});

/**
 * Hide and show App Banner
 * @param isVisible
 * @param analytics - track the show/hide event
 */

export const toggleMobileKeyboard = (isOpened: boolean): AppAction => ({
  type: 'TOGGLE_MOBILE_KEYBOARD',
  payload: isOpened,
});

export const fetchGroupsSuccess = (payload: Record<string, IGroup>) => ({
  type: 'FETCH_GROUPS_SUCCESS',
  payload,
});

export const setMetaLinks = (payload: {
  canonical?: string;
  alternate?: AlternateLink[];
}): AppAction => ({
  type: 'SET_META_LINKS',
  payload,
});

export const decrementPilingCounter = () => ({
  type: 'DECREMENT_PILING_COUNTER',
});

export const setDevice = (payload: Partial<AppState['device']>) => ({
  type: 'SET_DEVICE',
  payload,
});
