import {
  onModalToggle,
  showPersistentMessage,
  toggleOverlayPortal,
  toggleSnackbar,
} from './actions';

export enum BBCommonActionTypes {
  TOGGLE_OVERLAY_PORTAL = '[BBCommon] Toggle overlay portal',
  HIDE_SNACKBAR = '[BBCommon] Hide snackbar',
  TOGGLE_SNACKBAR = '[BBCommon] Toggle snackbar',
  RESET_PERSISTENT_MESSAGE = '[BBCommon] Reset persistent message',
  SHOW_PERSISTENT_MESSAGE = '[BBCommon] Show persistent message',
  HIDE_PERSISTENT_MESSAGE = '[BBCommon] Hide persistent message',
  ON_MODAL_TOGGLE = '[BBCommon] On modal toggle',
}

export type IToggleOverlayPortalAction = ReturnType<ReturnType<typeof toggleOverlayPortal>>;
export type IToggleSnackbarAction = ReturnType<ReturnType<typeof toggleSnackbar>>;
export type IShowPersistentMessageAction = ReturnType<ReturnType<typeof showPersistentMessage>>;
export type IOnModalToggleAction = ReturnType<typeof onModalToggle>;
